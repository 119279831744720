import dynamic from "next/dynamic";
import SbEditable from "storyblok-react";

import type { Articles as ArticlesType } from "./blocks/Articles";
import type { BlogBar as BlogBarType } from "./blocks/BlogBar";
import type { CallToAction as CallToActionType } from "./blocks/CallToAction";
import type { CardsBar as CardsBarType } from "./blocks/CardsBar";
import type { Categories as CategoriesType } from "./blocks/Categories";
import type { Checklist as ChecklistType } from "./blocks/Checklist";
import type { CodeEmbed as CodeEmbedType } from "./blocks/CodeEmbed";
import type { Columns as ColumnsType } from "./blocks/Columns";
import type { Comments as CommentsType } from "./blocks/Comments";
import type { ComparisonCard as ComparisonCardType } from "./blocks/ComparisonCard";
import type { DynamicHeader as DynamicHeaderType } from "./blocks/DynamicHeader";
import type { FourColumnText as FourColumnTextType } from "./blocks/FourColumnText";
import type { Gallery as GalleryType } from "./blocks/Gallery";
import type { Greenspark as GreensparkType } from "./blocks/Greenspark";
import type { Hero as HeroType } from "./blocks/Hero";
import type { Highlight as HighlightType } from "./blocks/Highlight";
import type { HighlightedText as HighlightedTextType } from "./blocks/HighlightedText";
import type { ImageBanner as ImageBannerType } from "./blocks/ImageBanner";
import type { Jobs as JobsType } from "./blocks/Jobs";
import type { NhIngredientCards as NhIngredientCardsType } from "./blocks/NhIngredientCards";
import type { NhUspBar as NhUspBarType } from "./blocks/NhUspBar";
import type { PopularProducts as PopularProductsType } from "./blocks/PopularProducts";
import type { ProductCardBig as ProductCardBigType } from "./blocks/ProductCardBig";
import type { ProductChoice as ProductChoiceType } from "./blocks/ProductChoice";
import type { QuestionList as QuestionListType } from "./blocks/QuestionList";
import type { RichText as RichTextType } from "./blocks/RichText";
import type { SmallBanner as SmallBannerType } from "./blocks/SmallBanner";
import type { Spacer as SpacerType } from "./blocks/Spacer";
import type { Stack as StackType } from "./blocks/Stack";
import type { Steps as StepsType } from "./blocks/Steps";
import type { StoryblokTable as StoryblokTableType } from "./blocks/StoryblokTable";
import type { TableImage as TableImageType } from "./blocks/TableImage";
import type { TableSmall as TableSmallType } from "./blocks/TableSmall";
import type { Team as TeamType } from "./blocks/Team";
import type { TextBanner as TextBannerType } from "./blocks/TextBanner";
import type { Timeline as TimelineType } from "./blocks/Timeline";
import type { Title as TitleType } from "./blocks/Title";
import type { TrippleQuote as TrippleQuoteType } from "./blocks/TrippleQuote";
import type { UspBar as UspBarType } from "./blocks/UspBar";
import type { Video as VideoType } from "./blocks/Video";
import type { Videowise as VideowiseType } from "./blocks/Videowise";
import type { Image as ImageType } from "./components/Image";
import { Container } from "./molecules/Container";

const BlogBar = dynamic(() =>
  import("./blocks/BlogBar").then((mod) => mod.BlogBar)
) as typeof BlogBarType;
const CardsBar = dynamic(() =>
  import("./blocks/CardsBar").then((mod) => mod.CardsBar)
) as typeof CardsBarType;
const CallToAction = dynamic(() =>
  import("./blocks/CallToAction").then((mod) => mod.CallToAction)
) as typeof CallToActionType;
const Categories = dynamic(() =>
  import("./blocks/Categories").then((mod) => mod.Categories)
) as typeof CategoriesType;
const Hero = dynamic(() =>
  import("./blocks/Hero").then((mod) => mod.Hero)
) as typeof HeroType;
const Highlight = dynamic(() =>
  import("./blocks/Highlight").then((mod) => mod.Highlight)
) as typeof HighlightType;
const ImageBanner = dynamic(() =>
  import("./blocks/ImageBanner").then((mod) => mod.ImageBanner)
) as typeof ImageBannerType;
const PopularProducts = dynamic(() =>
  import("./blocks/PopularProducts").then((mod) => mod.PopularProducts)
) as typeof PopularProductsType;
const ProductChoice = dynamic(() =>
  import("./blocks/ProductChoice").then((mod) => mod.ProductChoice)
) as typeof ProductChoiceType;
const UspBar = dynamic(() =>
  import("./blocks/UspBar").then((mod) => mod.UspBar)
) as typeof UspBarType;
const ProductCardBig = dynamic(() =>
  import("./blocks/ProductCardBig").then((mod) => mod.ProductCardBig)
) as typeof ProductCardBigType;
const DynamicHeader = dynamic(() =>
  import("./blocks/DynamicHeader").then((mod) => mod.DynamicHeader)
) as typeof DynamicHeaderType;
const TextBanner = dynamic(() =>
  import("./blocks/TextBanner").then((mod) => mod.TextBanner)
) as typeof TextBannerType;
const SmallBanner = dynamic(() =>
  import("./blocks/SmallBanner").then((mod) => mod.SmallBanner)
) as typeof SmallBannerType;
const Greenspark = dynamic(() =>
  import("./blocks/Greenspark").then((mod) => mod.Greenspark)
) as typeof GreensparkType;
const Videowise = dynamic(() =>
  import("./blocks/Videowise").then((mod) => mod.Videowise)
) as typeof VideowiseType;
const TrippleQuote = dynamic(() =>
  import("./blocks/TrippleQuote").then((mod) => mod.TrippleQuote)
) as typeof TrippleQuoteType;
const Jobs = dynamic(() =>
  import("./blocks/Jobs").then((mod) => mod.Jobs)
) as typeof JobsType;
const Team = dynamic(() =>
  import("./blocks/Team").then((mod) => mod.Team)
) as typeof TeamType;
const Comments = dynamic(() =>
  import("./blocks/Comments").then((mod) => mod.Comments)
) as typeof CommentsType;
const Timeline = dynamic(() =>
  import("./blocks/Timeline").then((mod) => mod.Timeline)
) as typeof TimelineType;
const NhIngredientCards = dynamic(() =>
  import("./blocks/NhIngredientCards").then((mod) => mod.NhIngredientCards)
) as typeof NhIngredientCardsType;
const Articles = dynamic(() =>
  import("./blocks/Articles").then((mod) => mod.Articles)
) as typeof ArticlesType;
const Checklist = dynamic(() =>
  import("./blocks/Checklist").then((mod) => mod.Checklist)
) as typeof ChecklistType;
const CodeEmbed = dynamic(() =>
  import("./blocks/CodeEmbed").then((mod) => mod.CodeEmbed)
) as typeof CodeEmbedType;
const Gallery = dynamic(() =>
  import("./blocks/Gallery").then((mod) => mod.Gallery)
) as typeof GalleryType;
const RichText = dynamic(() =>
  import("./blocks/RichText").then((mod) => mod.RichText)
) as typeof RichTextType;
const Stack = dynamic(() =>
  import("./blocks/Stack").then((mod) => mod.Stack)
) as typeof StackType;
const Title = dynamic(() =>
  import("./blocks/Title").then((mod) => mod.Title)
) as typeof TitleType;
const Video = dynamic(() =>
  import("./blocks/Video").then((mod) => mod.Video)
) as typeof VideoType;
const StoryblokTable = dynamic(() =>
  import("./blocks/StoryblokTable").then((mod) => mod.StoryblokTable)
) as typeof StoryblokTableType;
const TableSmall = dynamic(() =>
  import("./blocks/TableSmall").then((mod) => mod.TableSmall)
) as typeof TableSmallType;
const Spacer = dynamic(() =>
  import("./blocks/Spacer").then((mod) => mod.Spacer)
) as typeof SpacerType;
const Image = dynamic(() =>
  import("./components/Image").then((mod) => mod.Image)
) as typeof ImageType;
const Columns = dynamic(() =>
  import("./blocks/Columns").then((mod) => mod.Columns)
) as typeof ColumnsType;
const HighlightedText = dynamic(() =>
  import("./blocks/HighlightedText").then((mod) => mod.HighlightedText)
) as typeof HighlightedTextType;
const NhUspBar = dynamic(() =>
  import("./blocks/NhUspBar").then((mod) => mod.NhUspBar)
) as typeof NhUspBarType;
const ComparisonCard = dynamic(() =>
  import("./blocks/ComparisonCard").then((mod) => mod.ComparisonCard)
) as typeof ComparisonCardType;
const FourColumnText = dynamic(() =>
  import("./blocks/FourColumnText").then((mod) => mod.FourColumnText)
) as typeof FourColumnTextType;
const TableImage = dynamic(() =>
  import("./blocks/TableImage").then((mod) => mod.TableImage)
) as typeof TableImageType;
const Steps = dynamic(() =>
  import("./blocks/Steps").then((mod) => mod.Steps)
) as typeof StepsType;
const QuestionList = dynamic(() =>
  import("./blocks/QuestionList").then((mod) => mod.QuestionList)
) as typeof QuestionListType;
const Sections = {
  blog_bar: BlogBar,
  cards_bar: CardsBar,
  call_to_action: CallToAction,
  categories: Categories,
  hero: Hero,
  highlight: Highlight,
  image_banner: ImageBanner,
  popular_products: PopularProducts,
  product_bar: PopularProducts,
  NH_product_bar: PopularProducts,
  product_choice: ProductChoice,
  usp_bar: UspBar,
  // product_card: ProductCardBig,
  dynamic_header: DynamicHeader,
  ingredient_cards: NhIngredientCards,
  text_banner: TextBanner,
  stack_copy: Stack,
  stack: Stack,
  small_banner: SmallBanner,
  greenspark: Greenspark,
  videowise: Videowise,
  tripple_quote: TrippleQuote,
  jobs: Jobs,
  team: Team,
  comments: Comments,
  timeline: Timeline,
  NH_ingredient_cards: NhIngredientCards,
  articles: Articles,
  checklist: Checklist,
  code_embed: CodeEmbed,
  gallery: Gallery,
  rich_text: RichText,
  title: Title,
  video: Video,
  simple_table: StoryblokTable,
  table_small: TableSmall,
  spacer_block: Spacer,
  image: Image,
  columns: Columns,
  NH_highlighted_text: HighlightedText,
  NH_usp_bar: NhUspBar,
  NH_comparison_card: ComparisonCard,
  NH_four_column_text: FourColumnText,
  NH_image_table: TableImage,
  NH_steps: Steps,
  NH_question_list: QuestionList,
};

export const DynamicSections = ({ sections, parentComponent = "" }) => {
  return sections?.length
    ? sections?.map((section, i) => {
        if (!!section && typeof Sections[section?.component] !== "undefined") {
          const Section = Sections[section?.component];

          if (
            section?.component === "hero" ||
            section?.component === "call_to_action" ||
            section?.component === "image_banner" ||
            section?.component === "spacer_block" ||
            parentComponent === "columns"
          ) {
            return (
              <Section
                key={section?._uid}
                content={section}
                first={i === 0}
                parentComponent={parentComponent}
              />
            );
          }
          return (
            <SbEditable content={section} key={section?._uid}>
              <Container
                space={[3, 4]}
                maxWidth={"1332px"}
                px={2}
                textAlign={section?.text_align}
                alignItems="start"
                withOverflowHidden={
                  section?.component === "dynamic_section" ||
                  section?.component === "highlight" ||
                  section?.component === "call_to_action" ||
                  section?.component === "usp_bar" ||
                  section?.component === "categories" ||
                  section?.component === "popular_products" ||
                  section?.component === "product_bar" ||
                  section?.component === "recipe" ||
                  section?.component === "recipe_bar" ||
                  section?.component === "dynamic_header"
                }
              >
                <Section
                  first={i === 0}
                  content={section}
                  parentComponent={parentComponent}
                />
              </Container>
            </SbEditable>
          );
        }

        return null;
      })
    : null;
};
